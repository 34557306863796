<script setup lang="ts"></script>

<template>
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_273_837)">
            <path
                d="M29.6875 14.25H28.5V9.5C28.5 4.26075 24.2393 0 19 0C13.7607 0 9.5 4.26075 9.5 9.5V14.25H8.3125C6.34917 14.25 4.75 15.8476 4.75 17.8125V34.4375C4.75 36.4024 6.34917 38 8.3125 38H29.6875C31.6508 38 33.25 36.4024 33.25 34.4375V17.8125C33.25 15.8476 31.6508 14.25 29.6875 14.25ZM12.6667 9.5C12.6667 6.00717 15.5072 3.16667 19 3.16667C22.4928 3.16667 25.3333 6.00717 25.3333 9.5V14.25H12.6667V9.5ZM20.5833 26.4765V30.0833C20.5833 30.9573 19.8756 31.6667 19 31.6667C18.1244 31.6667 17.4167 30.9573 17.4167 30.0833V26.4765C16.4746 25.9271 15.8333 24.9169 15.8333 23.75C15.8333 22.0036 17.2536 20.5833 19 20.5833C20.7464 20.5833 22.1667 22.0036 22.1667 23.75C22.1667 24.9169 21.5254 25.9271 20.5833 26.4765Z"
                fill="url(#paint0_linear_273_837)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_273_837"
                x1="19"
                y1="0"
                x2="19"
                y2="38"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_273_837">
                <rect width="38" height="38" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
